import React from "react";
import Layout from "components/layout";
import JobList from "components/job_list";
import EntryButton from "components/entry_button";
import Style from "styles/recruit_item.module.scss";
import SEO from "components/seo";

const Service = () => {
  return (
    <Layout>
      <SEO title="Projectmanager|Recruit" path="recruit/projectmanager" />
      <section className={Style.recruitItemHeading}>
        <div className={Style.recruitItemHeading__inner}>
          <h2 className={Style.recruitItemHeading__subtitle}>RECRUIT</h2>
          <h1 className={Style.recruitItemHeading__title}>PROJECT MANAGER</h1>
        </div>
      </section>
      <section className={Style.recruitItemOverview}>
        <div className={Style.recruitItemOverview__inner}>
          <table className={Style.recruitItemOverviewTable}>
            <tbody className={Style.recruitItemOverviewTable__tbody}>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>職種</th>
                <td className={Style.recruitItemOverviewTable__content}>プロジェクトマネージャー</td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>業務内容</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  新規サービスの開発、既存サービスのプロジェクト管理全般。
                  <br />
                  ※ECサイト、コンシューマー向けサービス、大規模ポータルサイトなど
                  <br />
                  　知名度の高いWEBシステムや事業、エンタメ系のサービス開発が多いです。
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>必須スキル・経験</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  プログラミング言語(Java, PHP, C#)等を使用した開発経験が5年以上
                  <br />
                  5名以上のプロジェクトマネージメント経験2年以上
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>歓迎するスキル・経験</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  BtoC向けサービスの開発経験
                  <br />
                  BtoC向けサービスのプロジェクトマネージメント経験
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>求める人物像</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  課題や問題が発生した際に、自ら積極的に取り組める人。
                  <br />
                  新しいことへの取り組みに貪欲であり、建設的な会話ができる提案ができる人。
                  <br />
                  報連相を意識し、周囲とコミュニケーションをとって仕事を遂行できる方。
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>給与</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  月収50万～
                  <br />
                  ※経験やスキルに応じて決定します
                  <br />
                  ※みなし残業20時間分含む。(超過分は別途残業代全額支給)
                  <br />
                  <br />
                  給与改定年2回
                  <br />
                  ※弊社の人事評価制度により給与改定を行います
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <EntryButton />

      <JobList />
    </Layout>
  );
};

export default Service;
